<template>
  <div class="auth__form__content" id="auth-login">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form
        @submit.prevent="handleSubmit(resetPassword)"
        class="is-label-14px-normal"
      >
        <h2 class="has-text-centered has-text-weight-black is-size-1">
          Reset Password
        </h2>
        <ValidationProvider
          name="password"
          v-slot="{ errors }"
          rules="required"
        >
          <b-field
            label="New Password"
            :type="errors[0] && 'is-danger'"
            :message="errors[0]"
          >
            <b-input
              type="password"
              v-model="password"
              password-reveal
              placeholder="Your New Password"
            ></b-input>
          </b-field>
        </ValidationProvider>
        <ValidationProvider
          name="confirm"
          v-slot="{ errors }"
          rules="required|confirmed:password"
        >
          <b-field
            label="Confirm Password"
            :type="errors[0] && 'is-danger'"
            :message="errors[0]"
          >
            <b-input
              type="password"
              v-model="confirmPassword"
              password-reveal
              placeholder="Your Confirmation Password"
            ></b-input>
          </b-field>
        </ValidationProvider>
        <b-field class="is-flex is-justify-content-center is-full password-btn">
          <b-button class="is-fullwidth" native-type="submit" type="is-primary">
            Reset Password
          </b-button>
        </b-field>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import ApiService from '@/services/common/api.service'

export default {
  data() {
    return {
      password: '',
      confirmPassword: '',
      isLoading: false,
      isSuccess: false,
    }
  },
  methods: {
    showToast(message, event) {
      this.$buefy.toast.open({
        duration: 5000,
        message: ` <b>${message}</b>`,
        position: 'is-bottom',
        type: event === 'failed' ? 'is-danger' : 'is-success',
      })
    },
    async resetPassword() {
      this.isLoading = true
      try {
        let response = await ApiService.put(
          '/api/space-roketin/reset-password',
          {
            forgotPasswordToken: this.$route.params.token,
            newPassword: this.confirmPassword,
          }
        )
        await this.$router.push('/auth/login')
        this.showToast(response.data.message, 'success')
      } catch (err) {
        this.showToast(err.response.data.message, 'failed')
      }
      this.isLoading = false
    },
  },
}
</script>
